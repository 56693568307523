import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";

import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Branding Project</Text>
                  <Title className="my-4">
                    Package Redisign <br />
                    JustCBD
                  </Title>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img
                src="/projects/justcbd/packaging.jpg?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    JustCBD is an edible CBD company available for purchase in
                    some stores and online. They were Established in 2017. They
                    have a range of gummie options available in different doses
                    of CBD. <br /> <br />
                    Their current packaging is similar to their competitors. The
                    containers are all clear but made of plastic with a twist
                    off top that can get gummed up with some of their products
                    that contain a sugar coating. <br /> <br />
                    The new package for this product features a small window to
                    allow the user to view the contents of the container while
                    also preserving the contents and avoiding exposure to light.
                    It also features a zip-top closure to keep the contents as
                    fresh as possible. The package is made of a environmentally
                    friendly packaging material in the same color as the label
                    from the previous packaging.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
          <div className="mt-lg-3 pt-5">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="8" className="mb-4 mb-lg-0">
                  <Text variant="tag">Tools Used</Text>
                  <Title variant="cardBig" className="mt-3">
                    Photoshop, Illustrator
                  </Title>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Section bg="dark" className="pt-12">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/nobel-prize-poster">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Nobel Prize Poster - Subrahmanyan Chandrasekhar
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
